import React from "react";
import Layout from "../containers/Layout";
import {graphql, HeadFC} from "gatsby";
import Seo from "../containers/Seo";

function SamplePage(): JSX.Element {
  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <div className="container">
            <h1>参考動画 リンク集</h1>
          </div>
          <div className="zag"/>
        </div>

        <div className="container">
          <div className="warning-bg">
            <p>
              どのような動画を撮影するかのサンプルです。<br />
              ※こちらの参考動画は当サイトとは無関係の動画です。
            </p>

            <p>
              <a href="https://youtu.be/LjV8sLmg5Qs" target="_blank" rel="noreferrer noopener">参考動画1 (Youtube)</a>
            </p>

            <p>
              <a href="https://youtu.be/JWMVTpnZKvQ" target="_blank" rel="noreferrer noopener">参考動画2 (Youtube)</a>
            </p>

            <p>
              <a href="https://youtube.com/shorts/TbsL7c4Y4Fw?feature=share" target="_blank" rel="noreferrer noopener">参考動画3 (Youtube)</a>
            </p>

            <p>
              <a href="https://youtu.be/1VeXLI74yiA" target="_blank" rel="noreferrer noopener">参考動画4 (Youtube)</a>
            </p>

            <p>
              <a href="https://wakisky.com/post/w0003/" target="_blank">参考動画5 (wakisky.com)</a>
            </p>


            <p>
              <a href="https://wakisky.com/post/w0002/" target="_blank">参考動画6 (wakisky.com)</a>
            </p>


            <p>
              <a href="https://wakisky.com/post/w0001/" target="_blank">参考動画7 (wakisky.com)</a>
            </p>

          </div>
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query SampleQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">参考動画リンク集 | {data.site.siteMetadata.title}</title>
    <meta id="description" name="description" content={"撮影の参考になる動画のリンク集です。ここに掲載している動画は当サイトとは関係ありません。"} />
  </Seo>
);

export default SamplePage;